import { Box, SxProps, Theme } from '@mui/material';

interface PageContainerProps {
  children: React.ReactNode;
  sx?: SxProps<Theme>;
}

export const PageContainer: React.FC<PageContainerProps> = ({
  children,
  sx = {
    p: 3,
  },
}) => {
  return <Box sx={sx}>{children}</Box>;
};
