export interface SchoolEntity {
  schoolId: string;
  schoolName: string;
  contactInfo: string;
  numberOfStudents: number;
  currentMTSSFocus: string;
  existingChallenges: string;
  additionalInformation: string;
  timezoneId: string;
}

export interface SchoolDto {
  schoolId: string;
  schoolName: string;
  contactInfo: string;
  numberOfStudents: number;
  currentMTSSFocus: string;
  existingChallenges: string;
  additionalInformation: string;
  timezoneId: string;
}

export class SchoolDto {
  constructor(schoolEntity: SchoolEntity) {
    this.schoolId = schoolEntity.schoolId;
    this.schoolName = schoolEntity.schoolName;
    this.contactInfo = schoolEntity.contactInfo;
    this.numberOfStudents = schoolEntity.numberOfStudents;
    this.currentMTSSFocus = schoolEntity.currentMTSSFocus;
    this.existingChallenges = schoolEntity.existingChallenges;
    this.additionalInformation = schoolEntity.additionalInformation;
    this.timezoneId = schoolEntity.timezoneId;
  }

  static createSchoolDtoFromEntity(schoolEntity: SchoolEntity): SchoolDto {
    return new SchoolDto(schoolEntity);
  }
}
