import { createTheme } from '@mui/material/styles';

// Define the light theme settings
const lightTheme = createTheme({
  palette: {
    mode: 'light',
  },
});

// Define the dark theme settings
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#0f83f0',
    },
    secondary: {
      main: '#f07c0f',
    },
    background: {
      default: '#192231',
      paper: '#24344d',
    },
  },
});

export { lightTheme, darkTheme };
