import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { graphConfig, tokenRequest } from '../constants/authConfig';

import { msalInstance } from '../index';

export async function callMsGraph(accessToken: string) {
  try {
    if (!accessToken) {
      const account = msalInstance.getActiveAccount();
      if (!account) {
        throw new Error(
          'No active account! Verify a user has been signed in and setActiveAccount has been called.',
        );
      }

      try {
        const response = await msalInstance.acquireTokenSilent({
          ...tokenRequest,
          account: account,
        });
        accessToken = response.accessToken;
      } catch (error) {
        console.error('Error acquiring the access token silently:', error);

        if (error instanceof InteractionRequiredAuthError) {
          // The user needs to be re-authenticated or consent is required
          try {
            const response = await msalInstance.acquireTokenPopup({
              ...tokenRequest,
              account: account,
            });
            accessToken = response.accessToken;
          } catch (popupError) {
            console.error('Error acquiring token via popup:', popupError);
            // Optionally fall back to redirect
            msalInstance.acquireTokenRedirect({
              ...tokenRequest,
              account: account,
            });
            return;
          }
        } else {
          // Handle other errors
          console.error(
            'Unhandled MSAL error during silent token acquisition:',
            error,
          );
          throw error;
        }
      }
    }

    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append('Authorization', bearer);

    const options = {
      method: 'GET',
      headers: headers,
    };

    const response = await fetch(graphConfig.graphMeEndpoint, options);
    if (!response.ok) {
      throw new Error(
        `Graph API call failed with status ${response.status}: ${response.statusText}`,
      );
    }
    return await response.json();
  } catch (error) {
    console.error('Error calling MS Graph:', error);
    throw error;
  }
}

export async function getAccessToken() {
  const account = msalInstance.getActiveAccount();

  if (!account) {
    throw new Error(
      'No active account! Verify a user has been signed in and setActiveAccount has been called.',
    );
  }

  try {
    const response = await msalInstance.acquireTokenSilent({
      ...tokenRequest,
      account: account,
    });
    return response.accessToken;
  } catch (error) {
    console.error('Error acquiring the access token:', error);

    if (error instanceof InteractionRequiredAuthError) {
      // The user needs to be re-authenticated or consent is required
      try {
        const response = await msalInstance.acquireTokenPopup({
          ...tokenRequest,
          account: account,
        });
        return response.accessToken;
      } catch (popupError) {
        console.error('Error acquiring token via popup:', popupError);
        // Optionally fall back to redirect
        msalInstance.acquireTokenRedirect({
          ...tokenRequest,
          account: account,
        });
      }
    } else {
      // Handle other errors
      console.error('Unexpected error:', error);
      throw error;
    }
  }
}
