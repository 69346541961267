import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  SyntheticEvent,
} from 'react';
import { useGetSearchForStudentName } from '../../services/schools.service';
import {
  Autocomplete,
  CircularProgress,
  TextField,
  useEventCallback,
} from '@mui/material';
import { useClientStore } from '../../client-store/store';

export type StudentForAutocomplete = {
  id: string;
  name: string;
  studentNumber: string;
  school: string;
  schoolId: string;
};

interface StudentSearchForAccessProps {
  onChangeHandler: (
    studentId: string,
    newStudent: StudentForAutocomplete | null,
  ) => void;
}

export const StudentSearchForAccess: React.FC<StudentSearchForAccessProps> = ({
  onChangeHandler,
}) => {
  const selectedSchoolId = useClientStore.use.selectedSchoolId();
  const [inputValue, setInputValue] = useState<string>('');
  const [debouncedQuery, setDebouncedQuery] = useState<string>(inputValue);
  const [selectedValue, setSelectedValue] =
    useState<StudentForAutocomplete | null>(null);
  const debounceRef = useRef<NodeJS.Timeout | null>(null);

  const { data = [], isFetching } = useGetSearchForStudentName(debouncedQuery, [
    selectedSchoolId,
  ]);

  const debouncedSetQuery = useEventCallback(() => {
    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }
    debounceRef.current = setTimeout(() => {
      setDebouncedQuery(inputValue);
    }, 300);
  });

  useEffect(() => {
    debouncedSetQuery();
    return () => {
      if (debounceRef.current) {
        clearTimeout(debounceRef.current);
      }
    };
  }, [inputValue, debouncedSetQuery]);

  const handleInputChange = useEventCallback(
    (event: SyntheticEvent, newInputValue: string) => {
      setInputValue(newInputValue);
    },
  );

  const handleChange = useEventCallback(
    (event: SyntheticEvent, newValue: StudentForAutocomplete | null) => {
      onChangeHandler(newValue?.id ?? '', newValue);
      setSelectedValue(null); // Clear the selected value
      setInputValue(''); // Clear the input value
    },
  );

  const options = useMemo(() => data, [data]);

  return (
    <Autocomplete
      sx={{
        marginBottom: 4,
      }}
      color="primary"
      loading={isFetching}
      options={options}
      value={selectedValue}
      inputValue={inputValue}
      getOptionLabel={(option) =>
        option.studentNumber && option.studentNumber.trim() !== ''
          ? `${option.name} (${option.studentNumber})`
          : option.name
      }
      getOptionKey={(option) => option.id}
      onInputChange={handleInputChange}
      onChange={handleChange}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={(params) => (
        <TextField
          key={params.id}
          {...params}
          label="Student Search"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {isFetching ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};
