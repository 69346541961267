import { useQuery, useQueryClient } from '@tanstack/react-query';
import {
  UserWithSchoolsDto,
  UserWithSchoolsEntity,
} from '../dtos/userWithSchools.dto';
import intellitierAxios from '../lib/intellitierAxios';

const fetchUserWithSchools = async () => {
  const { data } = await intellitierAxios.get<UserWithSchoolsDto>(
    `/users/userWithSchools`,
    {
      transformResponse: [
        (data) => {
          const userWithSchoolsEntity: UserWithSchoolsEntity = JSON.parse(data);
          const userWithSchoolsDto =
            UserWithSchoolsDto.createUserWithSchoolsDtoFromEntity(
              userWithSchoolsEntity,
            );

          userWithSchoolsDto.schools.sort((a, b) =>
            a.schoolName.localeCompare(b.schoolName),
          );

          return userWithSchoolsDto;
        },
      ],
    },
  );

  return data;
};

export const usePrefechGetUserWithSchools = async () => {
  const queryClient = useQueryClient();

  await queryClient.prefetchQuery({
    queryKey: ['userWithSchools'],
    queryFn: () => fetchUserWithSchools(),
  });
};

export function useGetUserWithSchools() {
  return useQuery({
    queryKey: ['userWithSchools'],
    queryFn: async () => {
      const { data } = await intellitierAxios.get<UserWithSchoolsDto>(
        `/users/userWithSchools`,
        {
          transformResponse: [
            (data) => {
              const userWithSchoolsEntity: UserWithSchoolsEntity =
                JSON.parse(data);
              const userWithSchoolsDto =
                UserWithSchoolsDto.createUserWithSchoolsDtoFromEntity(
                  userWithSchoolsEntity,
                );

              userWithSchoolsDto.schools.sort((a, b) =>
                a.schoolName.localeCompare(b.schoolName),
              );

              return userWithSchoolsDto;
            },
          ],
        },
      );

      return data;
    },
  });
}
