import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  useEventCallback,
} from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useClientStore } from '../../client-store/store';
import { STUDENTS_ROUTE } from '../../constants/routes';
import { useViewNavigate } from '../../hooks/useViewNavigate';
import { usePrefetchGetEntityConfigForSchoolId } from '../../services/entityConfig.service';
import { useGetPermissionsForUserInSchool } from '../../services/permission.service';
import { useGetUserWithSchools } from '../../services/user.service';
import { DateUtilites, intellierDayJs } from '../../lib/intellitierDayJs';
import {
  getSelectedSchoolIdFromLocalStorage,
  storeSelectedSchoolIdInLocalStorage,
} from '../../utils/localStorage';

const selectStyles = {
  color: 'white',
  borderColor: 'white',
};

const formControlStyles = {
  borderColor: 'white',
};

export const SchoolSelect: React.FC = () => {
  const selectedSchoolId = useClientStore.use.selectedSchoolId();
  const updateSelectedSchoolId = useClientStore.use.updateSelectedSchoolId();
  const updateUserWithSchools = useClientStore.use.updateUserWithSchools();
  const updateUserRolePermissionsForSchool =
    useClientStore().updateUserRolePermissionsForSchool;
  const viewNavigate = useViewNavigate();
  const location = useLocation();

  const { isLoading, data: userWithSchools } = useGetUserWithSchools();
  const { data: userPermissionsForSchool } =
    useGetPermissionsForUserInSchool(selectedSchoolId);

  usePrefetchGetEntityConfigForSchoolId(selectedSchoolId);

  useEffect(() => {
    if (!userWithSchools) {
      return;
    }
    updateUserWithSchools(userWithSchools);

    let storedOrDefaultSchoolId: string | null =
      getSelectedSchoolIdFromLocalStorage();
    if (storedOrDefaultSchoolId) {
      updateSelectedSchoolId(storedOrDefaultSchoolId);
    } else {
      storedOrDefaultSchoolId = userWithSchools.schools[0].schoolId;
      updateSelectedSchoolId(storedOrDefaultSchoolId);
      storeSelectedSchoolIdInLocalStorage(storedOrDefaultSchoolId);
    }

    DateUtilites.setDefaultTimezone(
      userWithSchools.schools.find(
        (school) => school.schoolId === storedOrDefaultSchoolId,
      )?.timezoneId || intellierDayJs.tz.guess(),
    );
  }, [userWithSchools, updateSelectedSchoolId, updateUserWithSchools]);

  useEffect(() => {
    if (userPermissionsForSchool) {
      updateUserRolePermissionsForSchool(userPermissionsForSchool);
    }
  }, [userPermissionsForSchool, updateUserRolePermissionsForSchool]);

  const handleChange = useEventCallback((event: SelectChangeEvent) => {
    const schoolId = event.target.value as string;
    updateSelectedSchoolId(schoolId);
    storeSelectedSchoolIdInLocalStorage(schoolId);
    if (location.pathname.includes('student')) {
      viewNavigate(STUDENTS_ROUTE);
    }
  });

  const memoizedSchools = useMemo(
    () =>
      userWithSchools?.schools.map((school) => (
        <MenuItem key={school.schoolId} value={school.schoolId}>
          {school.schoolName}
        </MenuItem>
      )),
    [userWithSchools],
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <FormControl sx={formControlStyles}>
      <Select
        autoWidth
        displayEmpty
        labelId="school-select-label"
        id="school-select"
        value={selectedSchoolId}
        onChange={handleChange}
        variant="outlined"
        sx={selectStyles}
      >
        {memoizedSchools}
      </Select>
    </FormControl>
  );
};
