import { Grid } from '@mui/material';
import { useState } from 'react';
import { BatchAddInterventionForm } from '../components/BatchAddIntervention/BatchAddInterventionForm.component';
import { PageContainer } from '../components/shared/PageContainer';
import { SelectedStudentsTable } from '../components/shared/SelectedStudentsTable';
import {
  StudentForAutocomplete,
  StudentSearchForAccess,
} from '../components/UserPermissions/StudentSearchForAccess.component';

const BatchAddInterventionPage = () => {
  const [selectedStudents, setSelectedStudents] = useState<
    StudentForAutocomplete[]
  >([]);

  const handleStudentSelect = (
    studentId: string,
    student: StudentForAutocomplete | null,
  ) => {
    if (student && !selectedStudents.find((s) => s.id === studentId)) {
      setSelectedStudents((prevStudents) => [...prevStudents, student]);
    }
  };

  const handleRemoveStudent = (studentId: string) => {
    setSelectedStudents((prevStudents) =>
      prevStudents.filter((student) => student.id !== studentId),
    );
  };

  return (
    <PageContainer>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
        spacing={6}
      >
        <Grid item xs={12} md={6}>
          <StudentSearchForAccess onChangeHandler={handleStudentSelect} />

          {selectedStudents.length > 0 && (
            <SelectedStudentsTable
              selectedStudents={selectedStudents}
              onRemoveStudent={handleRemoveStudent}
            />
          )}

          <BatchAddInterventionForm
            selectedStudentsFromAutocomplete={selectedStudents}
          />
        </Grid>
      </Grid>
    </PageContainer>
  );
};

export default BatchAddInterventionPage;
