import { useEffect, useMemo, useState } from 'react';
import { setToLocalStorage, getFromLocalStorage } from '../utils/localStorage';
import { useClientStore } from '../client-store/store';
import { useMediaQuery } from '@mui/material';
import { darkTheme, lightTheme } from '../themes';

export const useTheme = () => {
  const selectedThemeName = useClientStore.use.selectedThemeName();
  const setThemeName = useClientStore.use.updateSelectedThemeName();
  const [themeLoaded, setThemeLoaded] = useState(false);
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const setNewThemeName = (theme: string) => {
    setToLocalStorage('theme', theme);
    setThemeName(theme as 'dark' | 'light');
  };

  // only load the initial theme once, then users can change it
  useEffect(() => {
    const localTheme = getFromLocalStorage<string>('theme');
    if (localTheme) {
      setNewThemeName(localTheme);
    } else {
      setNewThemeName(prefersDarkMode ? 'dark' : 'light');
    }
    setThemeLoaded(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const materialTheme = useMemo(
    () => (selectedThemeName === 'dark' ? darkTheme : lightTheme),
    [selectedThemeName],
  );

  return { selectedThemeName, themeLoaded, setNewThemeName, materialTheme };
};
