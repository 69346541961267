import {
  DetailedUserStudentPermissionsDto,
  DetailedUserStudentPermissionsEntity,
} from './detailedUserStudentPermissions.dto';

export interface PermissionRequestsEntity {
  permissionRequests: DetailedUserStudentPermissionsEntity[];
}

export interface PermissionRequestsDto {
  permissionRequests: DetailedUserStudentPermissionsDto[];
}

export class PermissionRequestsDto {
  constructor(permissionRequestsEntity: PermissionRequestsEntity) {
    this.permissionRequests = permissionRequestsEntity.permissionRequests.map(
      (request) =>
        DetailedUserStudentPermissionsDto.createDetailedUserStudentPermissionsDtoFromEntity(
          request,
        ),
    );
  }

  static createPermissionRequestsDtoFromEntity(
    permissionRequestsEntity: PermissionRequestsEntity,
  ): PermissionRequestsDto {
    return new PermissionRequestsDto(permissionRequestsEntity);
  }
}
