import { Button } from '@mui/material';
import {
  ArrowBack as ArrowBackIcon,
  Cancel as CancelIcon,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

interface BackOrCancelFormButtonProps {
  mode: 'view' | 'edit' | 'add';
}

const BackOrCancelFormButton: React.FC<BackOrCancelFormButtonProps> = ({
  mode,
}) => {
  const navigate = useNavigate();

  return (
    <Button
      variant="outlined"
      color="secondary"
      onClick={() => navigate(-1)}
      startIcon={mode === 'view' ? <ArrowBackIcon /> : <CancelIcon />}
      sx={{ marginRight: '.5rem' }}
    >
      {mode === 'view' ? 'Back' : 'Cancel'}
    </Button>
  );
};

export default BackOrCancelFormButton;
