import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import intellitierAxios from '../lib/intellitierAxios';
import {
  EntityConfigDto,
  EntityConfigEntity,
  EntityDtoName,
} from '../dtos/entityConfig.dto';
import { useClientStore } from '../client-store/store';

const fetchConfigs = async (schoolId?: string) => {
  const { data } = await intellitierAxios.get<EntityConfigDto[]>(
    schoolId
      ? `/config/entityConfig/school/${schoolId}`
      : `/config/entityConfig/default`,
    {
      transformResponse: [
        (data) => {
          const entityConfigEntities: EntityConfigEntity[] = JSON.parse(data);
          return entityConfigEntities.map((entityConfigEntity) => {
            return EntityConfigDto.createEntityConfigDtoFromEntity(
              entityConfigEntity,
            );
          });
        },
      ],
    },
  );

  // create a map from the entity config name to the entity config
  // this will allow us to easily look up the entity config by name
  const createdEntityConfigMap = new Map<EntityDtoName, EntityConfigDto>();
  data.forEach((entityConfig) => {
    createdEntityConfigMap.set(entityConfig.entityName, entityConfig);
  });
  useClientStore.setState({ entityConfigMap: createdEntityConfigMap });

  return data;
};

export function useGetEntityConfigForSchoolId(schoolId: string) {
  return useQuery({
    queryKey: ['config', 'entityConfig', 'school', schoolId],
    queryFn: () => fetchConfigs(schoolId),
    enabled: Boolean(schoolId),
  });
}

export const usePrefetchGetEntityConfigForSchoolId = async (
  schoolId: string,
) => {
  const queryClient = useQueryClient();

  await queryClient.prefetchQuery({
    queryKey: ['config', 'entityConfig', 'school', schoolId],
    queryFn: () => fetchConfigs(schoolId),
    staleTime: 15000,
  });
};

export const usePrefetchGetDefaultEntityConfig = async (schoolId: string) => {
  const queryClient = useQueryClient();

  await queryClient.prefetchQuery({
    queryKey: ['config', 'entityConfig', 'default'],
    queryFn: () => fetchConfigs(),
  });
};

export function useGetDefaultEntityConfig() {
  return useQuery({
    queryKey: ['config', 'entityConfig', 'default'],
    queryFn: () => fetchConfigs(),
    staleTime: 15000,
  });
}

export function useUpdateEntityConfigForSchool(schoolId: string) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (entityConfig: EntityConfigDto) => {
      return intellitierAxios.post<EntityConfigEntity>(
        `/config/entityConfig/school/${schoolId}`,
        {
          ...entityConfig,
        },
      );
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ['config'],
        refetchType: 'all',
      });
    },
    onError: (error, variables, context) => {
      // An error happened!
      console.log(`rolling back optimistic update with id ${context}`);
    },
  });
}
