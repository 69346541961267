import { ResourceTypes } from '../constants/resourceTypes';
import { PermissionsDto } from '../dtos/permissions.dto';
import { useClientStore } from '../client-store/store';

export const useUserHasRolePermissionForSchool = (
  resourceType: ResourceTypes,
  requiredPermissions: Array<keyof PermissionsDto>,
) => {
  const userRolePermissionsForSchool =
    useClientStore.use.userRolePermissionsForSchool();

  const resourcePermissions =
    userRolePermissionsForSchool.resourceTypePermissions.find(
      (permission) => permission.resourceType === resourceType,
    );

  if (!resourcePermissions) {
    return false;
  }

  return requiredPermissions.some(
    (permission) => resourcePermissions.permissions[permission],
  );
};
