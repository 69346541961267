import {
  AppBar,
  Toolbar,
  Typography,
  Drawer,
  Box,
  useTheme,
} from '@mui/material';
import { NavList } from './NavList.component';
import { SignInOrOutButton } from './SignInOrOutButton.component';
import { SchoolSelect } from './SchoolSelect.component';
import { useIsAuthenticated } from '@azure/msal-react';
import { DRAWER_WIDTH } from './constants';
import { usePrefechGetUserWithSchools } from '../../services/user.service';

const AppShell = ({ children }: { children: React.ReactNode }) => {
  const isAuthenticated = useIsAuthenticated();
  const theme = useTheme();
  usePrefechGetUserWithSchools();

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar position="fixed" sx={{ zIndex: theme.zIndex.drawer + 1 }}>
        <Toolbar
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{ display: 'flex', alignItems: 'center', width: DRAWER_WIDTH }}
          >
            <img
              src={`${process.env.PUBLIC_URL}/logo32.png`}
              alt="Neulight Logo"
              style={{ marginRight: theme.spacing(2) }}
            />
            <Typography variant="h6" noWrap component="div">
              IntelliTier
            </Typography>
          </Box>
          {isAuthenticated && <SchoolSelect />}
          <SignInOrOutButton />
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        sx={{
          width: DRAWER_WIDTH,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: DRAWER_WIDTH,
            boxSizing: 'border-box',
          },
        }}
      >
        <Toolbar />
        <NavList />
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          mt: '64px',
          overflowX: 'auto',
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default AppShell;
