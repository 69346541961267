import { CircularProgress, Grid } from '@mui/material';

interface CenteredPageCircularProgressProps {
  withTopMargin?: boolean;
}

export const CenteredPageCircularProgress = ({
  withTopMargin = false,
}: CenteredPageCircularProgressProps) => (
  <Grid
    container
    direction="column"
    alignItems="center"
    justifyContent="center"
    style={{ marginTop: withTopMargin ? '3rem' : 0 }}
  >
    <CircularProgress size={60} />
  </Grid>
);
