import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { BrowserUtils, InteractionStatus } from '@azure/msal-browser';
import { loginRequest } from '../../constants/authConfig';
import { useEventCallback } from '@mui/material';

export const SignInOrOutButton: React.FC = () => {
  const isAuthenticated = useIsAuthenticated();
  const { inProgress, instance } = useMsal();

  const onLoginSubmit = useEventCallback(async () => {
    instance.loginRedirect(loginRequest);
  });

  const onLogoutSubmit = useEventCallback(() => {
    instance.logoutRedirect({
      account: instance.getActiveAccount(),
      onRedirectNavigate: () => !BrowserUtils.isInIframe(),
    });
  });

  const isLoading =
    inProgress === InteractionStatus.Startup ||
    inProgress === InteractionStatus.HandleRedirect ||
    inProgress === InteractionStatus.Login;

  const buttonText = isAuthenticated ? 'Sign Out' : 'Sign In';

  return (
    <Button
      variant="contained"
      onClick={isAuthenticated ? onLogoutSubmit : onLoginSubmit}
      color="secondary"
      disabled={isLoading}
      startIcon={isLoading ? <CircularProgress size={20} /> : null}
    >
      {buttonText}
    </Button>
  );
};
