import { create } from 'zustand';
import { createSelectors } from './createSelectorHelper';
import { EntityConfigDto, EntityDtoName } from '../dtos/entityConfig.dto';
import { UserPermissionsDto } from '../dtos/userPermissions.dto';
import { UserWithSchoolsDto } from '../dtos/userWithSchools.dto';
import { StudentWithHistoryExpandedState } from '../pages/StudentWithHistory.page';

interface ClientState {
  selectedThemeName: 'light' | 'dark';

  // main data shared across components
  entityConfigMap: Map<EntityDtoName, EntityConfigDto>;
  selectedSchoolId: string;
  updateSelectedThemeName: (theme: 'light' | 'dark') => void;
  updateSelectedSchoolId: (
    selectedSchoolId: ClientState['selectedSchoolId'],
  ) => void;
  userWithSchools: UserWithSchoolsDto;
  updateUserWithSchools: (userWithSchools: UserWithSchoolsDto) => void;

  // permissions
  userRolePermissionsForSchool: UserPermissionsDto;
  updateUserRolePermissionsForSchool: (
    userPermissions: UserPermissionsDto,
  ) => void;

  userStudentPermissionsForSchool: UserPermissionsDto;
  setUserStudentPermissionsForSchool: (
    userPermissionsForStudent: UserPermissionsDto,
  ) => void;

  // expanded state of studentWithHistory Page
  studentWithHistoryExpandedState: StudentWithHistoryExpandedState;
  setStudentWithHistoryExpandedState: (
    expandedState: StudentWithHistoryExpandedState,
  ) => void;
}

/**
 * Since our store is small, we will start with a single store. In the future, if needed,
 * we can split the store into multiple slices and a bound store
 */
export const useClientStoreBase = create<ClientState>()((set) => ({
  selectedThemeName: 'light',

  // main data shared across components
  entityConfigMap: new Map<EntityDtoName, EntityConfigDto>(),
  selectedSchoolId: '',
  updateSelectedSchoolId: (schoolId) =>
    set(() => ({ selectedSchoolId: schoolId })),
  updateSelectedThemeName: (selectedThemeName: 'light' | 'dark') =>
    set(() => ({ selectedThemeName })),
  userWithSchools: UserWithSchoolsDto.createUserWithSchoolsDtoFromEntity({
    userId: '',
    email: '',
    schools: [],
    eligibleSchoolIds: [],
  }),
  updateUserWithSchools: (userWithSchools) => set(() => ({ userWithSchools })),

  // permissions
  userRolePermissionsForSchool:
    UserPermissionsDto.createUserPermissionsDtoFromEntity({
      resourceTypePermissions: [],
    }),
  updateUserRolePermissionsForSchool: (userRolePermissionsForSchool) =>
    set(() => ({ userRolePermissionsForSchool })),
  userStudentPermissionsForSchool:
    UserPermissionsDto.createUserPermissionsDtoFromEntity({
      resourceTypePermissions: [],
    }),
  setUserStudentPermissionsForSchool: (userStudentPermissionsForSchool) =>
    set(() => ({ userStudentPermissionsForSchool })),

  // expanded state
  studentWithHistoryExpandedState: {
    behavioralEvents: false,
    interventions: false,
  },
  setStudentWithHistoryExpandedState: (studentWithHistoryExpandedState) =>
    set(() => ({ studentWithHistoryExpandedState })),
}));

/**
 * This helper functions creates selectors for the store, so we can do the following:
 * get the property - const bears = useBearStore.use.bears()
 * get the action - const increment = useBearStore.use.increment()
 */
export const useClientStore = createSelectors(useClientStoreBase);
