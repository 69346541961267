import React from 'react';
import { Controller, Control, FieldValues, Path } from 'react-hook-form';
import { TextField } from '@mui/material';

interface TextFormFieldProps<T extends FieldValues> {
  name: Path<T>;
  label: string;
  control: Control<T>;
  multiline?: boolean;
  disabled?: boolean;
}

const TextFormField = <T extends FieldValues>({
  name,
  label,
  control,
  multiline = false,
  disabled = false,
}: TextFormFieldProps<T>) => (
  <Controller
    name={name}
    control={control}
    render={({ field }) => (
      <TextField
        {...field}
        label={label}
        fullWidth
        margin="normal"
        multiline={multiline}
        rows={multiline ? 3 : 1}
        disabled={disabled}
      />
    )}
  />
);

export default TextFormField;
