import {
  ResourceTypePermissionsDto,
  ResourceTypePermissionsEntity,
} from './resourceTypePermissions.dto';
import { RoleDto, RoleEntity } from './role.dto';

export interface RolePermissionsEntity {
  role: RoleEntity;
  resourceTypePermissions: ResourceTypePermissionsEntity[];
}

export interface RolePermissionsDto {
  role: RoleDto;
  resourceTypePermissions: ResourceTypePermissionsDto[];
}

export class RolePermissionsDto {
  constructor(rolePermissionsEntity: RolePermissionsEntity) {
    this.role = RoleDto.createRoleDtoFromEntity(rolePermissionsEntity.role);
    this.resourceTypePermissions =
      rolePermissionsEntity.resourceTypePermissions.map(
        (resourceTypePermission) =>
          new ResourceTypePermissionsDto(resourceTypePermission),
      );
  }

  static createRolePermissionsDtoFromEntity(
    rolePermissionsEntity: RolePermissionsEntity,
  ): RolePermissionsDto {
    return new RolePermissionsDto(rolePermissionsEntity);
  }
}
