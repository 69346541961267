import { SchoolDto, SchoolEntity } from './school.dto';

export interface UserWithSchoolsEntity {
  userId: string;
  email: string;
  schools: SchoolEntity[];
  eligibleSchoolIds: string[];
}

export interface UserWithSchoolsDto {
  userId: string;
  email: string;
  schools: SchoolDto[];
  eligibleSchoolIds: string[];
}
export class UserWithSchoolsDto {
  constructor(userWithSchoolsEntity: UserWithSchoolsEntity) {
    this.userId = userWithSchoolsEntity.userId;
    this.email = userWithSchoolsEntity.email;
    this.schools = userWithSchoolsEntity.schools.map((school) =>
      SchoolDto.createSchoolDtoFromEntity(school),
    );
    this.eligibleSchoolIds = userWithSchoolsEntity.eligibleSchoolIds;
  }

  static createUserWithSchoolsDtoFromEntity(
    userWithSchoolsEntity: UserWithSchoolsEntity,
  ): UserWithSchoolsDto {
    return new UserWithSchoolsDto(userWithSchoolsEntity);
  }
}
