import {
  RolePermissionsDto,
  RolePermissionsEntity,
} from './rolePermission.dto';

export interface SchoolRolesEntity {
  schoolId: string;
  roles: RolePermissionsEntity[];
}

export interface SchoolRolesDto {
  schoolId: string;
  roles: RolePermissionsDto[];
}

export class SchoolRolesDto {
  constructor(schoolRolesEntity: SchoolRolesEntity) {
    this.schoolId = schoolRolesEntity.schoolId;
    this.roles = schoolRolesEntity.roles.map(
      (role) => new RolePermissionsDto(role),
    );
  }

  static createSchoolRolesDtoFromEntity(
    schoolRolesEntity: SchoolRolesEntity,
  ): SchoolRolesDto {
    return new SchoolRolesDto(schoolRolesEntity);
  }
}
