import React, { ReactNode } from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useIsAuthenticated } from '@azure/msal-react';

export type NavItemProps = {
  active: boolean;
  name: string;
  onClick?: () => void;
  icon: ReactNode;
  link: string;
};

const NavItem: React.FC<NavItemProps> = ({ active, name, onClick, icon }) => {
  const isAuthenticated = useIsAuthenticated();

  return (
    <ListItem disablePadding key={name}>
      <ListItemButton disabled={!isAuthenticated} onClick={onClick}>
        <ListItemIcon
          sx={{
            color: active ? 'primary.main' : 'inherit',
          }}
        >
          {icon}
        </ListItemIcon>
        <ListItemText
          primary={name}
          primaryTypographyProps={{
            color: active ? 'primary' : 'inherit',
          }}
        />
      </ListItemButton>
    </ListItem>
  );
};

export default NavItem;
