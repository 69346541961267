import { InterventionDto, InterventionEntity } from '../dtos/intervention.dto';
import intellitierAxios from '../lib/intellitierAxios';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

export function useCreateInterventionForStudent(
  studentId: string,
  schoolId: string,
  onSuccessCb: () => void,
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (interventionDto: InterventionDto) => {
      return intellitierAxios.post<InterventionEntity>(
        `/schools/${schoolId}/students/${studentId}/intervention`,
        {
          ...interventionDto,
        },
      );
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ['studentWithHistory', studentId, schoolId],
      });
      onSuccessCb();
    },
    onError: (error, variables, context) => {
      // An error happened!
      console.log(`rolling back optimistic update with id ${context}`);
    },
  });
}

export function useGetInterventionById(
  studentId: string,
  schoolId: string,
  interventionId: string,
) {
  return useQuery({
    queryKey: [
      'schools',
      schoolId,
      'students',
      studentId,
      'intervention',
      interventionId,
    ],
    queryFn: async () => {
      const { data } = await intellitierAxios.get<InterventionDto>(
        `/schools/${schoolId}/students/${studentId}/intervention/${interventionId}`,
        {
          transformResponse: [
            (data) => {
              const interventionEntity: InterventionEntity = JSON.parse(data);
              return InterventionDto.createInterventionDtoFromEntity(
                interventionEntity,
              );
            },
          ],
        },
      );
      return data;
    },
    enabled: !!studentId && !!schoolId && !!interventionId,
  });
}

export function useDeleteInterventionForStudent(
  studentId: string,
  schoolId: string,
  onSuccessCb?: () => void,
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (interventionId: string) => {
      return intellitierAxios.delete(
        `/schools/${schoolId}/students/${studentId}/intervention/${interventionId}`,
      );
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ['studentWithHistory', studentId, schoolId],
      });
      onSuccessCb && onSuccessCb();
    },
    onError: (error, variables, context) => {
      // An error happened!
      console.log(`rolling back optimistic update with id ${context}`);
    },
  });
}
