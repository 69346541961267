import dayjs, { Dayjs } from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(localizedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

// start with guessing it, but once a school loads we'll update it.
const userTimezone = dayjs.tz.guess();
dayjs.tz.setDefault(userTimezone);
dayjs.locale('en-US');

export const intellierDayJs = dayjs;

export class DateUtilites {
  // TODO i need to figure out how to make react re-render when this default timezone changes, will update
  // in a future pr
  static setDefaultTimezone(timezone: string) {
    dayjs.tz.setDefault(timezone);
  }
  static formatDateToLocalDateTime(date: Dayjs) {
    return intellierDayJs(date).tz().format('L LT');
  }

  static createLocalTimeFromUtcDate(utcDate: string) {
    return intellierDayJs.utc(utcDate).tz();
  }
}
