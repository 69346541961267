import { Autocomplete, TextField } from '@mui/material';
import { Controller, Control, FieldValues, Path } from 'react-hook-form';

interface AutocompleteFormFieldProps<T extends FieldValues> {
  name: Path<T>;
  label: string;
  options: string[];
  control: Control<T>;
  disabled?: boolean;
}

const AutocompleteFormField = <T extends FieldValues>({
  name,
  label,
  options,
  control,
  disabled = false,
}: AutocompleteFormFieldProps<T>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Autocomplete
          disabled={disabled}
          value={value ? value.toString() : null}
          onChange={(event, newValue) => {
            onChange(newValue);
          }}
          options={options}
          getOptionLabel={(option) => option}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              variant="outlined"
              error={!!error}
              helperText={error ? error.message : null}
              fullWidth
              margin="normal"
            />
          )}
        />
      )}
    />
  );
};

export default AutocompleteFormField;
