import { DateUtilites } from '../lib/intellitierDayJs';
import {
  InterventionNoteDto,
  InterventionNoteEntity,
} from './interventionNote.dto';
import { Dayjs } from 'dayjs';
import { UserDto } from './user.dto';

export interface InterventionEntity {
  interventionId: string;
  interventionType: string;
  tier: string;
  description: string;
  interventionStartDate: string;
  interventionEndDate: string;
  progressRating: string; // 0 - 10 for user to track if it's helping with progress?
  interventionState: string; // Planned, InProgress, Cancelled, Closed
  interventionNotes: InterventionNoteEntity[];
  createdBy: string;
  createdByUser: UserDto;
  dateCreated: string;
  updatedBy: string;
  updatedByUser: UserDto;
  dateUpdated: string;
}

export interface InterventionDto {
  interventionId: string;
  interventionType: string;
  tier: string;
  description: string;
  interventionStartDate: Dayjs;
  formattedInterventionStartDate?: string;
  interventionEndDate: Dayjs;
  formattedInterventionEndDate?: string;
  progressRating?: string;
  interventionState: string;
  interventionNotes: InterventionNoteDto[];
  createdBy?: string;
  createdByUser?: UserDto;
  dateCreated?: Dayjs;
  formattedDateCreated?: string;
  updatedBy?: string;
  updatedByUser?: UserDto;
  dateUpdated?: Dayjs;
  formattedDateUpdated?: string;
}
export class InterventionDto {
  constructor(interventionEntity: InterventionEntity) {
    this.interventionId = interventionEntity.interventionId;
    this.interventionType = interventionEntity.interventionType;
    this.tier = interventionEntity.tier;
    this.description = interventionEntity.description;

    this.interventionStartDate = DateUtilites.createLocalTimeFromUtcDate(
      interventionEntity.interventionStartDate,
    );
    this.formattedInterventionStartDate =
      DateUtilites.formatDateToLocalDateTime(this.interventionStartDate);

    this.interventionEndDate = DateUtilites.createLocalTimeFromUtcDate(
      interventionEntity.interventionEndDate,
    );
    this.formattedInterventionEndDate = DateUtilites.formatDateToLocalDateTime(
      this.interventionEndDate,
    );

    this.progressRating = interventionEntity.progressRating;
    this.interventionState = interventionEntity.interventionState;
    this.interventionNotes =
      interventionEntity.interventionNotes?.map((interventionNoteEntity) =>
        InterventionNoteDto.createInterventionNoteDtoFromEntity(
          interventionNoteEntity,
        ),
      ) ?? [];

    this.dateCreated = DateUtilites.createLocalTimeFromUtcDate(
      interventionEntity.dateCreated,
    );
    this.formattedDateCreated = DateUtilites.formatDateToLocalDateTime(
      this.dateCreated,
    );
    this.createdBy = interventionEntity.createdBy;
    this.createdByUser = interventionEntity.createdByUser;

    this.dateUpdated = DateUtilites.createLocalTimeFromUtcDate(
      interventionEntity.dateUpdated,
    );
    this.formattedDateUpdated = DateUtilites.formatDateToLocalDateTime(
      this.dateUpdated,
    );
    this.updatedBy = interventionEntity.updatedBy;
    this.updatedByUser = interventionEntity.updatedByUser;
  }

  static createInterventionDtoFromEntity(
    interventionEntity: InterventionEntity,
  ): InterventionDto {
    return new InterventionDto(interventionEntity);
  }
}
